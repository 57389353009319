<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="CreateColor" @submit="CreateColor" >
          <CCardHeader>
            <CCol col="6" style="font-size: 25px;">إضافة لون</CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <CInput  label="Name" id="name" v-model="Color.name" placeholder="الاسم باللغة الإنجليزية" class="required" required/>
                <div v-if(errors.name) id="validateName" class="invalid-feedback">
                  {{errors.name}}
                </div>
              </CCol>
                 <CCol sm="6">
                <CInput  label="الاسم" id="name_ar" v-model="Color.name_ar" placeholder="الاسم باللغة العربية" class="required" required/>

              </CCol>
              <CCol sm="6">
                <CInput type="color" label="اللون" id="color_code" v-model="Color.color_code" placeholder="اللون" class="required" required/>
                <div v-if(errors.color_code) id="validateColorCode" class="invalid-feedback">
                  {{errors.color_code}}
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> حفظ</CButton>
              &emsp;
            <CButton type="reset" size="sm" color="danger" ><CIcon name="cil-ban"/> مسح </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import swal from 'sweetalert'
    export default {
        name: 'CreateColor',
        data: function() {
            return {
                Color: {},
                image:'',
                errors: []
            }
        },
        methods: {
            CreateColor: function(e) {
                e.preventDefault();
                let currentObj = this;
                let formData = new FormData();
                formData.append('name', this.Color.name);
                formData.append('name_ar', this.Color.name_ar);
                formData.append('color_code', this.Color.color_code);
                this.$http.post(`${process.env.VUE_APP_URL}colors`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        currentObj.output = response.data;
                        if(!response.data.error)
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                            currentObj.$router.push({ path: "/colors" });
                        }
                        else
                        {
                            currentObj.errors = response.data.data;
                        }

                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
            },
        },
    }
</script>
